import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ENVIRONMENT } from '../environment/environment.config';
import { Environment } from '../environment/environment.model';
import { Profile } from '../model/profile.model';
import { User } from '../model/user.model';
import { JwtHelper } from './jwt-helper.service';

const USER_SWITCHED_KEY = 'user-switched';
const DEFAULT_PROFILE: Profile = {
    category: null,
    categoryId : null,
    channel: null,
    contact: null,
    email: null,
    firstname: '',
    fullname: '',
    group: null,
    id: null,
    isBackend: null,
    isFrontend: null,
    lastname: '',
    establishmentId: null,
    // @deprecated BAR-808
    memberId: null,
    owner: null,
    role: null,
    roles: [],
    suppliers: [],
    username: null,
    zeltyApiKey: null,
    chain: null,
    commercialName: null,
    contactFunction: null,
    department: null,
    establishment: null,
    establishmentCode: null,
    establishmentType: null,
    firstContactDate: null,
    isOwner: false,
    isPro: false,
    logo: null,
    member: null,
    memberCode: null,
    networkCommercialName: null,
    sectorName: null,
};

@Injectable({providedIn: 'root'})
export class SessionService {
    user: User;

    fetchUser(): void {
        this.user = {
            profile: {...DEFAULT_PROFILE},
            ...this.getUser(),
        };
    }

    getToken(): string {
        return this.cookie.get(this.environment.tokenCookieName);
    }

    getUser(): User {
        if (this.isUserSwitched()) {
            return {
                profile: this.userSwitched,
            };
        }

        try {
            return JSON.parse(localStorage.getItem('user'));
        } catch (e) {
            this.logout();
        }
    }

    get userSwitched(): Profile|null {
        return JSON.parse(localStorage.getItem(USER_SWITCHED_KEY));
    }

    get originUser(): User|null {
        if (!this.isUserSwitched()) {
            return null;
        }

        return JSON.parse(localStorage.getItem('user'));
    }

    get isUserManager(): boolean {
        return 222 === this.user.profile.id // lexik :fire:
            || 1397 === this.user.profile.id // mercier
            || 6 === this.user.profile.id // drouet
            || 1021 === this.user.profile.id // vidal
            || 7 === this.user.profile.id // heraud
            || 3 === this.user.profile.id // admin
            || 968 === this.user.profile.id // letinturier
            || 1213 === this.user.profile.id; // ddelavigne
    }

    isUserPro(): boolean {
        return this.user && this.user.profile.isPro;
    }

    isUserSwitched(): boolean {
        return null !== this.userSwitched;
    }

    isValid(): boolean {
        return !JwtHelper.isExpired(this.getToken());
    }

    logout(): void {
        this.cookie.deleteAll('/');
        localStorage.removeItem('user');
        this.user = {profile: {...DEFAULT_PROFILE}};
        this.resetUserSwitched();
        window.location.href = '/';
    }

    resetUserSwitched(): void {
        localStorage.removeItem(USER_SWITCHED_KEY);
    }

    storeUser(user: User): void {
        this.user = {...user};
        localStorage.setItem('user', JSON.stringify({profile: user.profile}));
    }

    switchUser(user: Profile): void {
        localStorage.setItem(USER_SWITCHED_KEY, JSON.stringify(user));
    }

    constructor(
        private cookie: CookieService,
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {}
}
