import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[ngbControl]',
})
export class NgbControlDirective implements OnInit, OnChanges {
    @HostBinding('class.form-check-input') formCheckInput = false;
    @HostBinding('class.form-control') formControlClass = true;
    @Input() label: string = null;
    @Input() ngbControl = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ngbControl) {
            this.makeReadonly(changes.ngbControl.currentValue);
        }
    }

    ngOnInit(): void {
        if (
            !this.isCustom && (
                'checkbox' === this.elem.nativeElement.getAttribute('type') ||
                'radio' === this.elem.nativeElement.getAttribute('type')
            )
        ) {
            this.formControlClass = false;
            this.formCheckInput = true;
        }

        this.makeReadonly(this.ngbControl);

        if (this.isCustom) {
            this.formControlClass = false;
            this.formCheckInput = true;
        }
    }

    get isCustom(): boolean {
        return this.ngbControl.toLowerCase().includes('custom');
    }

    private makeReadonly(ngbControlValue: string): void {
        if (!ngbControlValue.toLowerCase().includes('readonly')) {
            return;
        }

        if (this.formControlClass && !this.isCustom) {
            this.elem.nativeElement.classList.add('form-control-plaintext');
        }

        this.elem.nativeElement.setAttribute('readonly', 'readonly');
        this.elem.nativeElement.setAttribute('disabled', 'disabled');
        if ('TEXTAREA' === this.elem.nativeElement.nodeName) {
            this.elem.nativeElement.style.resize = 'none';
        }
    }

    constructor(public elem: ElementRef) {}
}
